/*
|--------------------------------------------------------------------------
| colores
|--------------------------------------------------------------------------
*/
$white: #ffffff;
$verdema: #b5be9c;
$black: #000;
$grey: #ccc;
$azul: #8fcad6;
$azul2: #454c5e;
$azul3: #e6e1e9;
$azuldrk: #133a61;
$azul4: #f3f5fa;
$azul5: #eaf0fa;
$azul6: #e2e5ea;
$azul7: #a6c8e4;
$gris1: #ccc;
$gris12: #aaaaaa;
$gris2: #8e8e8e;
$gris3: #7e7e7e;
$gris4: #2e2e2e;
$gris5: #a5a5a5;
$gris6: #7c7c7c;
$gris7: #444444;
$gris8: #d7d7d7;
$grisolivo: #4f5655;
$beige: #e4d2be;
$beige2: #f9f6f3;
$beige3: #f4efea;
$beige4: #7b746e;
$beige5: #deddd7;
$beige6: #967a64;
$beige7: #cec7bd;
$marron: #250e09;
$marron2: #80665f;
$marron3: #251305;

// fondos y algunos elementos cercanos a negro.
$negrosgral: #1e1e1e;


// fondos home
$gris8: #f2f2f2;

// Otros
$verdegris: #b6bd9c;
$sec1: #f9f9f7;
$sec2: #f2f3f1;
$sec3: #e4e1df;
$sec4: #77d3f3;
$sec5: #d9a976;
$sec6: #16262e;






