/*
|--------------------------------------------------------------------------
| Workzentre Styles
|--------------------------------------------------------------------------
*/
@import "elements/colors";
@import "elements/fonts";

html {}
body {
  color: $gris5;
  font-size: 12px;
  font-family: $font-gotbook;
  line-height: unset;
}
hr.separator {
	width: 100%;
	display: block;
	height: 1px;
	background: none;
	border: none;
	margin: 1rem auto;
	box-shadow: none;
}
hr.mediumSeparator {
	@extend hr.separator;
	margin: 1.5rem auto;
}
hr.bigSeparator {
	@extend hr.separator;
	margin: 2rem auto;
}
hr.hugeSeparator {
  @extend hr.separator;
  margin: 4rem auto;
}

hr.invSeparator {
  width: 100% !important;
  display: block !important;
  height: 1px !important;
  background: none !important;
  border: none !important;
  margin: 1rem auto !important;
  box-shadow: none !important;
}

h3 {
  font-size: 18px;
  font-family: $font-gotbook;
}

.mg-contact-info {
  li {
    margin-bottom: 3px;
  }

  li.espaciado {
    margin-left: 35px;
  }
}

.vacio {
  margin-left: 17px;
  i {
    width: 17px;
  }
}

.btn {
  font-size: 16px;
}

.subtitulos {
  color: $gris6;
}
.roomsmoving {
  font-family: $font-gar;
  color: $gris7;
  -webkit-animation: animate_header 15s;
    animation: animate_header 15s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    h2 {
    	color: $marron3 !important;
    }

    p {
      font-style: italic;
      font-size: 1.2em;
    }
}


@keyframes animate_header {
    0%   {background:$sec1;}
    25%  {background:$sec2;}
    50% {background:$sec3;}
    75%  {background:$sec2;}
    100%   {background:$sec1;}
}

@-webkit-keyframes animate_header {
    0%   {background:$sec1;}
    25%  {background:$sec2;}
    50% {background:$sec3;}
    75%  {background:$sec2;}
    100%   {background:$sec1;}
}


.headeranimado {
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
}

.barramenu {
  background: rgba(22, 38, 46, 0.7) !important;
}


#vestibulov4 {
  max-height: 350px;
  background: none;
}

#vestibulov3 {
  .barra {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    min-height: 50px;
    background: rgba(0,0,0,0.1);
    text-align: center;
    color: $azul;
    z-index: 1312312;
    font-size: 1.1em;
    text-transform: uppercase;
    padding: 15px 0;
    ul {
      list-style: none;
      margin: auto;
      width: 330px;
      text-align: center;
      li {
        text-align: center;
        float: left;
        margin: 0 10px;
        display: inline-block;
      }
    }
  }
}

.mafont {
  height: 130px;
}

.abajo {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10%;
  min-height: 50px;
  text-align: center;
  color: $azul;
  z-index: 1312312;
  font-size: 4em;
  img {
    max-width: 40px;
    margin: auto;
  }
}

.abajoslider {
  img {
    max-width: 40px;
    margin: auto;
  }
}

.flechaabajo {
  width: 80px;
  height: 80px;
  margin: auto;
  background: $azul;
  -webkit-mask: url(/images/abajo.svg) center / contain no-repeat;
  -webkit-animation: animate_fdo 45s;
      animation: animate_fdo 45s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
}

@-webkit-keyframes animate_fdo {
    0%   {background:$sec4;}
    25%  {background:$sec5;}
    50% {background:$sec6;}
    75%  {background:$sec5;}
    100%   {background:$sec4;}
}
#motorhome {
  position: fixed;
  bottom: 0px;
  z-index: 999;
  width: 100%;
  padding: 0;
  color: $beige2;

  .mg-bn-title {
    box-shadow: none;
    color: $beige2;
    font-size: 16px;

    span {
      color: $beige2;
    }
  }
}

#motorhomemovil {
  position: fixed;
  bottom: 0px;
  z-index: 999;
  width: 100%;
  padding: 0;
  color: $beige2;

  .mg-bn-title {
    box-shadow: none;
    color: $beige2;
    font-size: 16px;

    span {
      color: $beige2;
    }
  }
}

.input-group-addon {
  color: $beige2 !important;
  border-color: $beige2 !important;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

div.cs-skin-elastic{
   span {
      color: $beige;
      border-color: $beige4;
   }
}

.scrollerbarra {
  bottom: 80px;
  z-index: 50 !important;
}

.mg-generico-bco {
  a:hover {
    .overlayimg {
      position: relative;
      overflow: hidden;
      img {
        animation: kenburns 10s 1 !important;
      }
    }
  }


  a:link {
    .overlayimg {
      position: relative;
      overflow: hidden;
      img {
        animation: kenburnssalida 5s;
      }
    }
  }
  figcaption {
     h2 {
      color: $gris6;
      text-align: center;
      font-size: 18px;
      font-family: $font-gotbook;
     }

     p {
      text-align: center;

     }
  }
}


@keyframes kenburns {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    50% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes kenburnssalida {
    0% {
       transform: scale(auto);
        -webkit-transform: scale(auto);
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}


.mg-generico {

  a:hover {
    .overlayimg {
      position: relative;
      overflow: hidden;
      img {
        animation: kenburns 10s 1 !important;
      }
    }
  }

  figcaption {
     h2 {
      color: $gris6;
      text-align: center;
      font-size: 18px;
      font-family: $font-gotbook;
     }

     p {
      text-align: center;

     }
  }
  p {
      text-align: center;

     }
}

.mg-rooms {
  figcaption {
     h2 {
      color: $gris6;
      text-align: center;
      font-size: 18px;
      font-family: $font-gotbook;
     }

     p {
      text-align: center;

     }
  }
}

.mg-dining {

  padding: 70px 0 80px;

  figcaption {
     h2 {
      color: $gris6;
      text-align: center;
      font-size: 18px;
      font-family: $font-gotbook;
     }
     p {
      text-align: center;

     }

  }
  h3 {
      text-align: center;
      font-size: 18px;
      font-family: $font-gotbook;
     }
}

.sinpadding {
    padding: 0px !important;
}

.motorredes {
  a {
    color: $white;
    i {
      font-size: 2em;
      vertical-align: middle;
      vertical-align: -webkit-baseline-middle;
    }
  }
}

.getmore {
  background: $verdema;
  width: 100%;
  height: 240px;
  display: block;
  color: $white;
  font-size: 1.8em;
  font-family: $font-gotbook;
  text-align: center;
  vertical-align: middle;
  vertical-align: webkit-baseline-middle;
  padding-top: 108px;
  i {
    font-size: 1.5em;
    vertical-align: middle;
    vertical-align: webkit-baseline-middle;
  }
  a {
    color: $white;
  }
}


.waterheader{
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/water/header.jpg);
  color: $white;
  text-align: center;
  font-family: $font-gar;
  p {
    font-style: italic;
    font-size: 1.2em;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $gris4;
  }
}

.wellnessheader{
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/wellness/header.jpg);
  color: $white;
  text-align: center;
  font-family: $font-gar;
  p {
    font-style: italic;
    font-size: 1.2em;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
}

ul.listaderecha {
  // list-style-position: right;
  direction: rtl;
}

ul.listaspa {
  margin-top: 10px;
  li {
    padding: 6px;
    text-align: left;
    span {
    }
  }
}

.residencesheader {
  p {
    font-style: italic;
    font-size: 1.2em;
  }
}

.cuisineheader{
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/cuisine/header.jpg);
  color: $white;
  text-align: center;
  font-family: $font-gar;
  p {
    font-style: italic;
    font-size: 1.2em;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
 
}

.eventsheader{
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/events/header.jpg);
  color: $white;
  text-align: center;
  font-family: $font-gar;
  p {
    font-style: italic;
    font-size: 1.2em;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
 
}


#agua {
  background: #f7feff !important;
}

.azules {
  color: $azuldrk !important;
}

.fdogris {
  background: $negrosgral !important;

  p {
    color: $gris2;
  }
  .btn-dark {
    background: none;
    color: $gris2;
    font-family: $font-gotlight;
    border: 1px solid rgba(255,255,255,0);
    font-weight:200;
  }
  .btn-dark:hover {
    background-color: none;
    border: solid 1px $gris2;
    color: $gris2;
    font-weight:200;
  }
} 

#sustaintability {
  background: $azul4;
  h2 {
    color: $azuldrk;
  }
}

#restdining {
  background: #f6f7ff !important;

  
}

.mg-marcosanim {
  .fa-file-pdf-o {
    font-size: 3em;
  }
}

#venues {
  background-color: $beige2 !important;
}

#roomsdiv {
  background-color: $beige2 !important;
}

#suitesdiv {
  background-color: $beige2 !important;
}


#villasdiv {
  background-color: $beige2 !important;
}

#homeexperience {
  background: $beige2 !important;
}

#homeeventos {
  background: #fffbf5 !important;
}





#residences {
  .subheader {
    font-family: $font-gar;
    font-style: italic;
    font-size: 1.2em;
    color: $gris5;
  }

  span {
    font-size: 1.4em;
  }
}
#ceremonies {
  background-color: #f8fdff !important;
  .subheader {
    font-family: $font-gar;
    font-style: italic;
    font-size: 1.2em;
    color: $gris5;
  }

  span {
    font-size: 1.4em;
  }
}
#galadedanza {
  background-color: $white !important;
  .subheader {
    font-family: $font-gar;
    font-style: italic;
    font-size: 1.2em;
    color: $gris5;
  }

  span {
    font-size: 1.4em;
  }
}
#events {
  background-color: #f9f6ef !important;
  .subheader {
    font-family: $font-gar;
    font-style: italic;
    font-size: 1.2em;
    color: $gris5;
  }

  span {
    font-size: 1.4em;
  }
}
.musicandarts {
  .subheader {
    font-family: $font-gar;
    font-style: italic;
    font-size: 1.2em;
    color: $gris5;
  }

  span {
    font-size: 1.4em;
  }
}

#headermusicarts {
  p {
    font-style: italic;
    font-family: $font-gar;
    font-size: 1.2em;
  }
}

.modales {
  font-style: italic;
  font-family: $font-gar;
  padding: 30px;
  border-radius: 0;
  .modal-header {
    background: $white;
  }
  .modal-content {
    padding: 30px;
    border-radius: 0;
  }

  p.modalheader {
    font-size: 1.1em;
    text-align: center;
  }
  p.primerparrafo {
    font-family: $font-gotlight;
    font-style: normal;
    font-size: 1.1em;
    text-align: center;
  }

  h2 {
    text-align: center;
    font-family: $font-gotlight;
    font-style: normal;
  }
  .imagenmodal {
    img {
      vertical-align: middle;
      vertical-align: webkit-baseline-middle;
      margin-top: 40px;
    }
  }
  .logomodal {
    text-align: center;
    img {
      max-width: 90px;
      margin: auto;
    }
  }

  .logomodalmaa {
    text-align: center;
    img {
      max-width: 120px;
      margin: auto;
    }
  }

  .logomodalmed {
    text-align: center;
    img {
      max-width: 400px;
      margin: auto;
    }
  }
  .close {
    font-family: $font-gotlight;
    font-style: normal;
  }
}

.overlaylogo {
  position: absolute;
  width: 100%;
  top: 167px;
  left: 0;
  text-align: center;
  h2 {
    font-family: $font-gotlight;
    color: $white;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 35px;
    margin-top: 0;
    font-size: 45px;
  }
  h2:after {
    content: '';
    width: 110px;
    height: 3px;
    background-color: $verdegris;
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -55px;
  }
  p {
    font-style: italic;
    font-family: $font-gar;
    font-size: 1.2em;
  }
}

.loscabosheader{
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/loscabos/header.jpg);
  color: $white;
  text-align: center;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
  p {
    font-style: italic;
    font-family: $font-gar;
    font-size: 1.2em;
  }
}

.ceremoniesheader {
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/ceremonies/header.jpg);
  background-position: bottom center;
  background-size: 100% auto;
  color: $beige5 !important;
  text-align: center;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
  p {
    font-style: italic;
    font-family: $font-gar;
    font-size: 1.2em;
  }
  h2 {
    color: $beige5 !important;
  }
}
.galaheader {
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/gala/header.jpg);
  background-position: bottom center;
  background-size: 100% auto;
  color: $beige5 !important;
  text-align: center;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
  p {
    font-style: italic;
    font-family: $font-gar;
    font-size: 1.2em;
  }
  h2 {
    color: $beige5 !important;
  }
}
.galaheader2 {
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/gala/gala1.jpg);
  background-position: bottom center;
  background-size: 100% auto;
  color: $beige5 !important;
  text-align: center;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
  p {
    font-style: italic;
    font-family: $font-gar;
    font-size: 1.2em;
  }
  h2 {
    color: $beige5 !important;
  }
}

.galleryheader{
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/gal/header.jpg);
  color: $white;
  text-align: center;
  font-family: $font-gar;
  p {
    font-style: italic;
    font-size: 1.2em;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
}

.presentacionesheader{
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/presentaciones/header.jpg);
  color: $white;
  text-align: center;
  font-family: $font-gar;
  p {
    font-style: italic;
    font-size: 1.2em;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
}


.scheduleheader{
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(/images/lib/schedule/header.jpg);
  color: $white;
  text-align: center;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    background: $black;
  }
}


.mg-about-features {

  h3 {
    text-align: center;
  }
}

.mg-book-now {

  .abrircerrar {
    padding-top: 10px;
    height: 50px;
    i {
      font-size: 2.5rem;
      margin-left: 7px;
    }
    font-size: 1.2em;
    text-align: center;
  }


  .mg-bn-forms {
    padding: 30px 0px;

    .input-group-addon {
      border-color: $beige4 !important;
      color: $beige !important;
      padding: 3px 7px;
    }
    .form-control {
      color: $beige;
      border-color: $beige4;
      line-height: unset !important;
      padding: 3px 12px 1px;
      height: 25px;
      max-width: 138px;

    }
    .form-control::-webkit-input-placeholder {
       color: $beige;
     }

     .form-control:-moz-placeholder { /* Firefox 18- */
       color: $beige;  
     }

     .form-control::-moz-placeholder {  /* Firefox 19+ */
       color: $beige;  
     }

     .form-control:-ms-input-placeholder {  
       color: $beige;  
     }
     .motbengine {
      margin-right: 21px;
     }
  }
  .mg-bn-title {
    line-height: 23px;
    padding: 24px 0px;
  }
}

.mg-map-gala {
  width: 100%;
  height: 300px;
}

.boton-book {
  left: 0px;
  top: 9px;
}


.datepicker {
  position: fixed !important;
  bottom: 68px !important;
  top: unset !important;
  background: $beige2 !important;
}

.cs-select {
  .cs-options {
    bottom: 31px !important;
  }
  span {
    padding: 3px 12px;
    width: 107px;
  }
  .children {

  }

  .adults {

  }
}

#children {
  width: 107px;
}

.sticky-on {
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -ms-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
  opacity: 0.9;

  position: fixed;
  top: 0;
  z-index: 47239847;

  .mg-search-box {
    background-color: rgba(22, 38, 46, 0.95);
  }
  .navbar-inverse {
    background-color: rgba(22, 38, 46, 0.95);

    .navbar-nav {
      li {
        a {
          padding-top: 25px;
          padding-bottom: 25px;
        }
      }
    }

  }

  .navbar-brand {
    img {
      height: 50px;
    }
  }
  .center-content {
    .navbar-header {
      .navbar-brand {
        display: none;
      }
    }
  }
  .mg-search-box-cont {
    margin: 12px 20px 13px;
  }
}

.sticky {
  opacity: 1;
}

.mg-sec-title {
  h2 {
    color: #545454;
  }
  p {
    font-family: $font-gar;
    font-style: italic;
    font-size: 1.2em;
    line-height: 14px;
    color: $gris5;
  }
}

.cielo {
  color: $azul7 !important;
}

.grises1 {
  color: $gris1 !important;
  span {
    color: $gris12 !important;
  }
}

.gristits {
  color: $gris2 !important;
}

.beiges {
  color: $beige !important;
}

.beiges2 {
  color: $beige2 !important;
}

.beiges5 {
  color: $beige5 !important;
}

.beiges6 {
  color: $beige6 !important;
}

.beiges7 {
  color: $beige7 !important;
}

.grises3 {
  color: $gris3 !important;
}

.grises4 {
  color: $gris4 !important;
}

.grisolivo {
  color: $grisolivo !important;
}

.grises8 {
  color: $gris8 !important;
}

.marrones {
  color: $marron !important;
}

.marrones2 {
  color: $marron2 !important;
}

.azules2 {
  color: $azul2 !important;
}

.azules3 {
  color: $azul3 !important;
}

.azules4 {
  color: $azul4 !important;
}

.azules5 {
  color: $azul5 !important;
}



.btn-dark {
  background: none;
  color: $black;
  font-family: $font-gotlight;
  border: 1px solid rgba(255,255,255,0);
   
}
.btn-dark:hover {
  background-color: none;
  border: solid 1px $negrosgral;
  color: $negrosgral;
}


.mg-avl-room {
  a:hover {
    .overlayimg {
      position: relative;
      overflow: hidden;
      img {
        animation: kenburns 12s ease-in-out;
      }
    }
  }
}

.mg-avl-room-title {
  a {
    color: $gris2 !important;
    }
}

.mg-gallery-thumb {
    margin: 10px auto !important;
    padding: 0;
    list-style: none;
    max-width: 330px;
    position: relative;
    bottom: unset !important;
    right: unset !important;
    left: unset !important;
}


.owl-theme {
  .owl-controls {
    .owl-buttons {
      div {
        background: none;
        font-size: 4em;
      }
      div:hover {
        background: none;
        font-size: 4em;
      }
    }
  }
}

ul.linksmotor {
  height: 85px;
  padding: 11px 0;
  list-style: none;
  color: $beige;
  font-family: $font-gar;
  font-style: italic;
  font-size: 1.3em;
    li {
      height: 80px;
      float: left;
      margin-left: 16px;
      i {
        font-size: 1.6em;
      }
      a {
        height: 80px;
        display: block;
        padding-top: 21px;
        color: $beige;
        img {
          margin-top: -13px;
          max-height: 50px; 
          padding: 5px;
        }
      }
      img {
        margin-top: -9px;
        max-height: 50px; 
        padding: 5px;
        }
    }
}


.video-container {
  min-height: 500px;
  width: 100%;
}



.mg-gallery {
  max-height: 620px;
}
.owl-item {
  li {
    img {
      max-height: 600px;
      margin: auto;
    }
  }
}

li.galthumb {
  overflow: hidden;
  width: 100px;
  height: 60px;
  text-align: center;
  img {
    max-height: 70px;
    margin: auto;
    vertical-align: webkit-baseline-middle;
    vertical-align: middle;
  }
}

footer.footermotor {
  padding-bottom: 95px;
}



.art-bd-img {
  display: none !important;
}

.overlayinsta {
  width: 90%;
  height: 99%;
  position: absolute;
  left: auto;
  right: auto;
  top: 0px;
  bottom: auto;
  z-index: 999;
  cursor: pointer;
}

.navbar-toggle {
  border-color: #a5a5a5 !important;
}

.navbar-toggle:active {
    background: none !important;
    border-color: #a5a5a5 !important;
}

.navbar-toggle:focus {
    background: none !important;
    border-color: #a5a5a5 !important;
}

.navbar-toggle:hover {
    background: none !important;
    border-color: #a5a5a5 !important;
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #a5a5a5;
}



@import "elements/media";