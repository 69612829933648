/*==========  Non-Mobile First Method  ==========*/

    /* Large Devices, Wide Screens */
    @media only screen and (max-width : 1200px) {
        // .navbar-brand {
        //     margin: 12px 0 !important;
        //     img {
        //         max-width: 160px !important;
        //         height: auto !important;
        //     }
        // }

        #motorhome {

        }


        .solodesktop {
            display: none !important;
        }

        .btn-dark {
            margin-bottom: 10px;
        }
    }

    /* Medium Devices, Desktops */
    @media only screen and (max-width : 992px) {
        
        #vestibulov3 { 
            display: none !important;
        }

        #motorhomemovil {
           display: none;
        }

        #motorhome {
           // display: none;
           height: 215px;
           position: fixed;
           bottom: -150px;
        }

        .navbar-inverse {
            .navbar-nav  {
                li { 
                    a {
                        font-size: 10px;
                    }
                }
            }
        }

        .navbar-brand {
            img {
                margin: auto;
                float: none;
                margin-top: 20px;
            }
        }


        // campos del motor.
        .mg-book-now .mg-bn-forms .form-control {
            max-width: unset !important;
            height: 36px;
        }

        .mg-book-now .mg-bn-forms {
            padding: 10px 0px 30px 0px;
        }

        ul.linksmotor li {
            height: 60px;
        }
        ul.linksmotor {
            height: 60px;
            padding: 0;
        }
        .boton-book {
            left: 0px;
            top: -22px;
             width: 80px;
            height: 80px;
        }
        .btn-dark {
            margin-bottom: 30px;
        }
    }



    /* Small Devices, Tablets */
    @media only screen and (max-width : 768px) {

        .header .navbar-collapse {
            max-height: 480px !important;
            width: 100%;
            text-align: center;
        }

        .navbar-inverse .navbar-nav > li ul.dropdown-menu {
             width: 100%;
            text-align: center;
        }

        .navbar-inverse .navbar-nav li a {
            font-size: 12px;
            padding: 12px 20px !important;
        }


        .mg-gallery-thumb {
            display: none !important;
        }
        .owl-item {
            li {
                img {
                    max-height: 280px;
                    margin: auto;
                }
            }
        }

        .mg-bn-forms {
            padding: 30px 0px;
        }

        #motorhomemovil {
           display: block;
        }

        #motorhome {
           // display: none;
           height: 215px;
        }

        .scrollerbarra {
            display: none;
        }

        .imagenmodal {
            img {
                vertical-align: middle;
                vertical-align: webkit-baseline-middle;
                margin-top: 50px;
            }
        }
        .logomodal {
            text-align: center;
            img {
                width: 60px;
                margin: auto;
            }
        }
        
        .boton-book {
            width: 80px;
            height: 80px;
            left: -14px;
            top: 10px;
            position: absolute;
        }

        .navbar-toggle {
            margin-top: 28px !important;
        }

        .navbar-brand {
            padding: 1px 0 20px 0 !important;
        }

        .motbengine {
            margin-bottom: 10px;
        }

        #mdesplegable {
        margin-top: 20px;
      }
        

    }


    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 480px) {

        
        footer.footermotor {
          padding-bottom: 64px;
      }


        .waterheader{
            background-image: url(/images/lib/water/headermovil.jpg);
            background-attachment: fixed !important;
            background-position: 0px 0px !important;
            background-size: 768px;
        }

        .wellnessheader{
            background-image: url(/images/lib/wellness/headermovil.jpg);
            background-attachment: fixed !important;
            background-position: inherit !important;
            background-size: 100% auto !important;
        }

        .cuisineheader{
            background-image: url(/images/lib/cuisine/diningmovil.jpg);
            background-attachment: fixed !important;
            background-position: inherit !important;
            background-size: 100% auto !important;
        }
        

        .ceremoniesheader{
            background-image: url(/images/lib/ceremonies/headermovil.jpg);
            background-attachment: fixed !important;
            background-position: inherit !important;
            background-size: 100% auto !important;
        }

        .eventsheader{
            background-image: url(/images/lib/events/headermovil.jpg);
            background-attachment: fixed !important;
            background-position: inherit !important;
            background-size: 100% auto !important;
        }

        
        .mg-gallery-thumb {
            display: none !important;
        }
        .owl-item {
            li {
                img {
                    max-height: 280px;
                    margin: auto;
                }
            }
        }
         .mg-bn-forms {
            padding: 10px 0px !important;
        }

        ul.linksmotor {
            height: 50px;
            li {

            }
        }

        .navbar-toggle {
            margin-top: 28px !important;
        }

        .navbar-brand {
            padding: 1px 0 20px 0 !important;
        }

        .parallax {
          /*background-attachment: scroll;*/
          // background-position: center center !important;
          // background-attachment: scroll;
          // background-size: cover;
          // background-repeat: no-repeat;
      }

      #mdesplegable {
        margin-top: 20px;
      }


    }

    @media only screen and (max-width : 390px) {
        .mg-gallery-thumb {
            display: none !important;
        }
        .owl-item {
            li {
                img {
                    max-height: 280px;
                    margin: auto;
                }
            }
        }

        .mg-page-title h2 {
            font-size: 33px;
        }   

    }

    /* Custom, iPhone Retina */ 
    @media only screen and (max-width : 320px) {
        .mg-gallery-thumb {
            display: none !important;
        }
        .owl-item {
            li {
                img {
                    max-height: 280px;
                    margin: auto;
                }
            }
        }

    }

    // ipad mini ambos
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 1)  { 

}
// ipad mini vertical
    @media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 1)  { 

}





